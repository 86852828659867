"use client";

import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React from "react";
import { cn } from "~/utils/cn";

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-10 items-center justify-center rounded bg-background p-1 text-dimmed transition duration-1000",
      className
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "inline-flex items-center justify-center whitespace-nowrap rounded-full px-3 py-1.5 font-sans text-sm ring-offset-background transition-all duration-200 data-[state=active]:bg-lavender-700 data-[state=active]:text-foreground data-[state=active]:shadow-sm focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      className
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-2 ring-offset-background transition focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

// Custom for the jobs section

const TabsExampleJobsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex  bg-charcoal-900 p-1 text-muted-foreground transition duration-1000",
      className
    )}
    {...props}
  />
));
TabsExampleJobsList.displayName = TabsPrimitive.List.displayName;

const TabsExampleJobsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "rounded border border-grid-dimmed p-2 text-left font-sans text-sm text-charcoal-500 ring-offset-background transition-all duration-200 data-[state=active]:bg-grid-dimmed data-[state=active]:text-foreground data-[state=active]:opacity-100  hover:bg-grid-dimmed  hover:opacity-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 sm:h-auto sm:p-3 lg:max-w-full lg:p-4 ",
      className
    )}
    {...props}
  />
));

TabsExampleJobsTrigger.displayName = TabsPrimitive.Trigger.displayName;

// Custom for the Hero section
const TabsHeroTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "group inline-flex h-[2.5rem] w-full items-center justify-center gap-2 whitespace-nowrap rounded border border-grid-dimmed bg-background pl-2 pr-3 text-base text-charcoal-300 ring-offset-background transition-all duration-200 data-[state=active]:bg-charcoal-750 data-[state=active]:text-bright data-[state=active]:opacity-100 data-[state=active]:shadow-sm hover:bg-charcoal-750 hover:text-bright hover:opacity-100 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
      className
    )}
    {...props}
  >
    {props.children}
  </TabsPrimitive.Trigger>
));
TabsHeroTrigger.displayName = TabsPrimitive.List.displayName;

export {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  TabsExampleJobsList,
  TabsExampleJobsTrigger,
  TabsHeroTrigger,
};

import React from "react";
import { motion } from "framer-motion";

import {
  Tabs,
  TabsContent,
  TabsExampleJobsTrigger,
  TabsList,
} from "./primitives/Tabs";
import { BookOpenIcon } from "@heroicons/react/24/outline";
import { CodeBlock } from "./code/CodeBlock";
import { AnimatingArrow } from "./primitives/AnimatingArrow";
import { LinkButton } from "./primitives/Buttons";
import { Section } from "./primitives/Section";
import { Paragraph } from "./primitives/text/Paragraph";
import { SectionHeader } from "./primitives/text/SectionHeader";
import { Header4 } from "./primitives/text/Header";

interface Tab {
  id: string;
  title: string;
  body: string;
  code: string;
}

interface CodeViewerSectionProps {
  tabs: Tab[];
  sectionTitle: string;
  docsLink?: string;
  sectionId: string;
  hidePattern?: boolean;
  showCopyButton?: boolean;
}

const containerVariants = {
  visible: { transition: { staggerChildren: 0.1 } },
};

const tabVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: { type: "ease-in-out" },
  },
};

export function CodeViewerSection({
  tabs,
  sectionTitle,
  docsLink,
  sectionId,
  hidePattern,
  showCopyButton = false,
}: CodeViewerSectionProps) {
  return (
    <Section id={sectionId}>
      <SectionHeader
        title={sectionTitle}
        hidePattern={hidePattern}
        buttons={
          docsLink ? (
            <LinkButton
              variant="minimal/medium"
              to={docsLink}
              target="_blank"
              LeadingIcon={BookOpenIcon}
              trailingIconClassName="group-hover:translate-x-1 duration-200"
              TrailingIcon={() => (
                <AnimatingArrow variant="small" direction="topRight" />
              )}
            >
              Docs
            </LinkButton>
          ) : null
        }
      />
      <div className="grid max-w-[76.5rem] grid-cols-12 grid-rows-[1fr_auto] xl:mx-auto">
        <Tabs
          defaultValue={tabs[0].id}
          className="relative col-span-12 grid grid-cols-12 border-grid-dimmed md:border-t lg:mx-3 lg:border-l xl:mx-0"
        >
          <TabsList className="sticky top-[60px] z-10 col-span-12 grid h-full flex-1 -translate-y-0.5 grid-flow-col items-start justify-start overflow-x-scroll rounded-none border-y border-grid-dimmed py-3 pl-3 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-charcoal-700 md:px-3 md:py-3 lg:col-span-4 lg:translate-y-0 lg:border-y-0">
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              variants={containerVariants}
              className="grid grid-flow-col gap-3 lg:h-fit lg:grid-flow-row"
            >
              {tabs.map((tab) => (
                <motion.div
                  variants={tabVariants}
                  key={tab.id}
                  className="flex flex-1"
                >
                  <TabsExampleJobsTrigger
                    value={tab.id}
                    className="group flex h-full w-60 flex-col justify-start sm:w-80 lg:h-fit lg:w-full"
                  >
                    <Header4 variant="small" className="pb-2">
                      {tab.title}
                    </Header4>
                    <Paragraph variant="small" className="">
                      {tab.body}
                    </Paragraph>
                  </TabsExampleJobsTrigger>
                </motion.div>
              ))}
            </motion.div>
          </TabsList>
          <div className="col-span-12 px-3 lg:col-span-8 lg:px-0">
            <div className="h-full border-x border-grid-dimmed p-3 lg:p-0">
              {tabs.map((tab) => (
                <motion.div
                  key={tab.id}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ amount: 0.1, once: true }}
                >
                  <TabsContent
                    value={tab.id}
                    className="mx-0 mt-0 w-full items-center pr-4"
                  >
                    <CodeBlock
                      showLineNumbers={false}
                      code={tab.code}
                      removeBorder
                      showCopyButton={showCopyButton}
                    />
                  </TabsContent>
                </motion.div>
              ))}
            </div>
          </div>
        </Tabs>
      </div>
    </Section>
  );
}
